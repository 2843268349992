import React from 'react';
import './postPage.scss';
import PostContainer from './assets/postContainer/postContainer';

const Post = () => {
    return (
        <>
            <PostContainer />
        </>
    )
}

export default Post;