import React from "react";
import DownloadOur from "./components/downloadOur/downloadOur";
import Problems from "./components/Problems&Solution/problem";


import './downloadPage.scss'


const Download =() =>{
    return(
        <>
        <DownloadOur />
        <Problems />
        </>
    )
}
export default Download